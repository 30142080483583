import styles from "./Udid.module.css";
import { isMobileSafari } from 'react-device-detect';
import {Navigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {atApiHost, getIsSafariStatus, getScreenHeight} from "../../utils";
import {Toast} from "react-vant";


export default function Udid() {

    // if (!isMobileSafari) {
    //     return (
    //         Navigate({to:"/not-safari",replace:true})
    //     )
    // }

    const {code,error} = useParams()

    const downloadConfig = () => {
        if (!code) {
            Toast("获取失败：缺少兑换码")
            return
        }
        // 先校验兑换码
        location.href = atApiHost + `multi/mobile-config?code=${code}`
    }


    useEffect(() => {
        if (error) {
            Toast("兑换码错误")
        }
    },[])

    let maxHeight = getScreenHeight()

    let containerStyle = {
        width: "100%",
        height: `${maxHeight}px`,
        overflowY: "scroll",
    }


    return (
        <div style={containerStyle}>
            <div className={styles.headerContainer}>
                <p className={styles.headerTitle}>获取UDID</p>
                <button className={styles.headerButton} onClick={downloadConfig}>获取UDID信息</button>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.contentTitle}>步骤说明</div>

                <div className={styles.contentStepContainer}>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/udid/step1.png"
                        />
                        <div className={styles.contentStep1Desc}>请点击 允许 安装描述文件</div>
                    </div>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/udid/step2.png"
                        />
                        <div className={styles.contentStep1Desc}>回到桌面，打开 设置 - 通用，找到并点击打开 VPN 与设备管理</div>
                    </div>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/udid/step3.png"
                        />
                        <div className={styles.contentStep1Desc}>在弹出的页面中点击右上角的 安装 按钮，然后输入 锁屏密码 进行安装</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
