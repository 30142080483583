import axios from "axios";

import {v4 as uuidv4} from "uuid"
import {sdkReferCacheKey, defaultSdkRefer, sdkTokenCacheKey} from "../utils";

const host = process.env.NODE_ENV === 'production' ? 'https://app.supperapp666.pro' : ''
const token = localStorage.getItem(sdkTokenCacheKey)
let sdkRefer = localStorage.getItem(sdkReferCacheKey)
if (sdkRefer === null) sdkRefer = defaultSdkRefer
const client = axios.create({
    baseURL: `${host}/api/v2`,
    headers: {
        'Authorization': `Bearer ${token}`,
        'language': navigator.language,
        'Content-Type': 'application/json',
        'SDK-Refer': sdkRefer,
        'SDK-Version': "0.0.1",
        'Sys-Platform': 'iOS',
    }
})

export var autoRegister = async () => {
    let uniqueId = uuidv4()
    let data = {
        platform: 0,
        model: "Iphone",
        brand: "Apple",
        sys_version: "17.0.1",
        device_no: uniqueId,
        idfa: uniqueId,
        idfv: uniqueId,
        bundle_id: "com.wuyu.wsc",
        product_version: "0.0.1",
        refer: defaultSdkRefer
    }
    return new Promise((resolve, reject) => {
        return client.post('/auth/auto/register', data).then(res => {
            resolve(res.data)
        }).catch(res => {
            reject(res)
        })
    })
}

export var active = async () => {
    let uniqueId = uuidv4()
    let data = {
        platform: 0,
        model: "Iphone",
        brand: "Apple",
        sys_version: "17.0.1",
        device_no: uniqueId,
        idfa: uniqueId,
        idfv: uniqueId,
        bundle_id: "com.wuyu.wsc",
        product_version: "0.0.1",
        refer: defaultSdkRefer
    }
    return new Promise((resolve, reject) => {
        return client.post('/active', data).then(res => {
            resolve(res.data)
        }).catch(res => {
            reject(res)
        })
    })
}

export var order = async (data) => {
    let uniqueId = uuidv4()
    let commonData = {
        platform: 0,
        model: "Iphone",
        brand: "Apple",
        sys_version: "17.0.1",
        device_no: uniqueId,
        idfa: uniqueId,
        idfv: uniqueId,
        bundle_id: "com.wuyu.wsc",
        product_version: "0.0.1",
        refer: defaultSdkRefer
    }
    let params = {
        ...commonData,
        ...data
    }
    return new Promise((resolve, reject) => {
        return client.post('/pay/order/app/store', params).then(res => {
            resolve(res.data)
        }).catch(res => {
            reject(res)
        })
    })
}
