import styles from "./NotSafari.module.css"

import Clipboard from "clipboard"

export default function NotSafari() {

    // const copy = (str) => {
    //  Clipboard.copy(str)
    //  alert("复制成功")
    // }

    return (
        <div className={styles.container}>
            <img
                className={styles.icon}
                alt=""
                src="/images/common/safari.png"
            />
            <h2 className={styles.title}>请使用Safari浏览器打开</h2>
            {/*<div className={styles.linkContainer}>*/}
            {/*    <p className={styles.link}>https://www.baidu.comhttps://www.baidu.comhttps://www.baidu.comhttps://www.baidu.com</p>*/}
            {/*</div>*/}
            {/*<button className={styles.copyButton} onClick={() => copy("fff")}>复制链接</button>*/}
        </div>
    )
}
