import {
    Routes,
    Route,
    useNavigationType,
    useLocation,
    useNavigate,
    Navigate
} from "react-router-dom";

import TabBar from "./Components/TabBar/TabBar"

import React, {useEffect, useRef, useState} from "react";

import VConsole from 'vconsole';
import Me from "./pages/Me/Me";
import Error from "./pages/Error/Error";
import Order from "./pages/Order/Order";
import OrderDetail from "./pages/OrderDetail/OrderDetail";
import NotSafari from "./pages/NotSafari/NotSafari";
import Upgrade from "./pages/Upgrade/Upgrade";
import Udid from "./pages/Udid/Udid";
import Developer from "./pages/Developer/Developer";
import Detail from "./pages/Detail/Detail";
import Refer from "./pages/Refer/Refer";
import Home from "./pages/Home/Home";
import Goods from "./pages/Goods/Goods";
import {
    atTokenCacheKey,
    bottomHeightCacheKey,
    getIsSafariStatus,
    getScreenHeight,
    isSafariCacheKey,
    sdkTokenCacheKey
} from "./utils";
import {autoRegister} from "./api/at";
import {autoRegister as autoRegisterSdk} from "./api/sdk";
import LoadingView from "./Components/LoadingView/LoadingView";
import DetailNew from "./pages/DetailNew/DetailNew";

function App() {
    const action = useNavigationType();
    const location = useLocation();
    const pathname = location.pathname;
    const [tabShow, setTabShow] = useState(true)
    const [loaded,setLoaded] = useState(false)
    const notLoginPage = [
        "/detail",
        "/detail-new",
        "/udid",
        "/developer",
        "/not-safari",
        "/error",
    ]
    const tabPages = [
        "/",
        "/me",
    ]

    const [loading, setLoading] = useState(false)

    const loadInfo = async () => {
        let flag = true
        for (const page of notLoginPage) {
            let re = new RegExp(`^${page}`)
            if (pathname.match(re)) {
                flag = false
                return
            }
        }
        setLoading(true)
        let initStatus = false
        let refresh = false
        while (!initStatus) {
            let atToken = localStorage.getItem(atTokenCacheKey)
            if (!atToken) {
                try {
                    let response = await autoRegister()
                    if (response.status !== 1) {
                        continue
                    }
                    refresh = true
                    localStorage.setItem(atTokenCacheKey, response.data.access_token)
                } catch (e) {
                    continue
                }
            }
            let sdkToken = localStorage.getItem(sdkTokenCacheKey)
            if (!sdkToken) {
                try {
                    let responseSdk = await autoRegisterSdk()
                    if (responseSdk.status !== 1) {
                        continue
                    }
                    refresh = true
                    localStorage.setItem(sdkTokenCacheKey, responseSdk.data.access_token)
                } catch (e) {
                    continue
                }
            }
            initStatus = true
        }
        setLoading(false)
        if (refresh) {
            window.location.reload()
        }
    }

    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            const vConsole = new VConsole({theme: 'dark'});
        }
        loadInfo()
    }, [])

    useEffect(() => {
        if (action !== "POP") {
            window.scrollTo(0, 0);
        }
    }, [action, pathname]);

    useEffect(() => {
        let title = "应用多开";
        let metaDescription = "";
        // switch (pathname) {
        //   case "/":
        //     title = "首页";
        //     metaDescription = "";
        //     break;
        //   case "/me":
        //     title = "我的";
        //     metaDescription = "";
        //     break;
        //   case "/goods":
        //     title = "详情";
        //     metaDescription = "";
        //     break;
        // }

        if (title) {
            document.title = title;
        }

        if (metaDescription) {
            const metaDescriptionTag = document.querySelector(
                'head > meta[name="description"]'
            );
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription;
            }
        }
        setTabShow(tabPages.includes(pathname))
        setTimeout(() => {
            setLoaded(true)
        },500)
    }, [pathname]);

    let screenHeight = getScreenHeight()

    return (
        <>
            <div style={{position: "relative", height: screenHeight, flex: 1}}>
                <Routes>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/refer/:atRefer/:sdkRefer/:bottomHeight?" element={<Refer/>}/>
                    <Route path="/goods/:id" element={<Goods/>}/>
                    <Route path="/order" element={<Order/>}/>
                    <Route path="/order/:orderNo" element={<OrderDetail/>}/>
                    <Route path="/detail/:app_id/:active_code" element={<Detail/>}/>
                    <Route path="/detail-new/:app_id/:active_code?" element={<DetailNew />}/>
                    <Route path="/me" element={<Me/>}/>
                    <Route path="/upgrade" element={<Upgrade/>}/>
                    <Route path="/udid/:code/:error?" element={<Udid/>}/>
                    <Route path="/developer" element={<Developer/>}/>
                    <Route path="/not-safari" element={<NotSafari/>}/>
                    <Route path="/error" element={<Error/>}/>
                </Routes>
                {loaded && tabShow && (
                    <TabBar pathname={pathname} />
                )}
            </div>

            <LoadingView visible={loading} text='加载中...'/>
        </>
    );
}

export default App;
