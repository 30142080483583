import locals from "./locals";

const languages = ['zh-CN','zh','en']
const translate = (key) => {
  let lang = navigator.language
  if (!languages.includes(lang)) {
    lang = 'en'
  }
  if (lang === 'zh-CN') {
    lang = 'zh'
  }
  let obj = locals[lang]
  let key_arr = key.split('.')
  for (const keyArrElement of key_arr) {
    obj = obj[keyArrElement]
    if (obj === undefined) {
      break
    }
  }
  return obj
}

export default translate
