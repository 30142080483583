import {useNavigate, useParams} from "react-router-dom";
import {
    atReferCacheKey,
    atTokenCacheKey,
    bottomHeightCacheKey,
    isSafariCacheKey,
    sdkReferCacheKey,
    sdkTokenCacheKey
} from "../../utils";

export default function Refer() {
    const navigate = useNavigate()
    let {atRefer,sdkRefer} = useParams()
    // bottomHeight = Number(bottomHeight)
    // if (isNaN(bottomHeight)) {
    //     bottomHeight = 0
    // }
    let bottomHeight = "0"
    localStorage.setItem(atReferCacheKey, atRefer)
    localStorage.setItem(sdkReferCacheKey, sdkRefer)
    localStorage.setItem(bottomHeightCacheKey, bottomHeight)
    navigate("/")
    return null
}
