import styles from "./Order.module.css"
import {Image, List, NavBar} from "react-vant";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getOrders} from "../../api/at";
import {getIsSafariStatus, getScreenHeight} from "../../utils";
export default function Order() {
    const navigate = useNavigate()

    const [orders,setOrders] = useState([])

    const [finished, setFinished] = useState(false)
    const [page, setPage] = useState(1)
    const pageCount = 20

    const listLoad = async () => {
        await getOrdersRequest()
    }

    const getOrdersRequest = async () => {
        try {
            let response = await getOrders()
            let newList = response.data
            if (newList.length > 0) {
                // 未加载完毕
                if (page > 1) {
                    newList = orders.concat(...newList)
                }
                setOrders(newList)
                if (newList.length < pageCount) {
                    // 已经加载完
                    setFinished(true)
                    return
                }
                setPage(page + 1)
                return
            }
            // 已经加载完毕
            setFinished(true)
        }catch (e) {
            console.log(e)
            navigate(-1)
        }
    }

    let maxHeight = getScreenHeight()

    let containerStyle = {
        width: "100%",
        height: `${maxHeight}px`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        border: "1px solid red"
    }

    return (
        <div style={containerStyle}>
            <NavBar
                title="订单"
                safeAreaInsetTop
                placeholder
                onClickLeft={() => navigate(-1)}
            />
            <List  finished={finished} onLoad={listLoad} loadingText={<></>}
                   style={{flex: 1,overflowY:"scroll",paddingBottom: "80px"}}
            >
                {orders.map((item,index) => (
                    <div className={styles.orderItemContainer} key={item.id} onClick={() => navigate(`/order/${item.order_no}`)}>
                        <div className={styles.orderItemOutNoContainer}>
                            <span className={styles.orderItemOutNo}>订单编号：{item.order_no}</span>
                        </div>
                        <div className={styles.orderItemInfoContainer}>
                            <div className={styles.orderItemInfoLogo}>
                                {item.goods ? (
                                    <Image width={64} height={64} src={item.goods.cover ? item.goods.cover : '/images/common/wechat.png'} style={{borderRadius: '11px',overflow:'hidden'}} />
                                ) : (
                                    <Image width={64} height={64} src='/images/common/wechat.png' style={{borderRadius: '11px',overflow:'hidden'}} />
                                )}
                            </div>
                            <div className={styles.orderItemInfoRightContainer}>
                                <div className={styles.orderItemInfoRightTopContainer}>
                                    <div className={styles.orderItemInfoRightTopTitleContainer}>
                                        <span className={styles.orderItemInfoTitle}>{item.goods?.title}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </List>
        </div>
    )
}
