import styles from "./Home.module.css"
import React, {useEffect, useState} from "react";
import {Image,NavBar,Button,Cell,Toast,List} from "react-vant";
import {getGoods} from "../../api/at";
import {useNavigate} from "react-router-dom";
import {atTokenCacheKey, getIsSafariStatus, getScreenHeight, sdkTokenCacheKey} from "../../utils";
import LoadingViewGuide from "../../Components/LoadingViewGuide/LoadingViewGuide";


export default function Home() {
    const navigate  = useNavigate()
    const [goods,setGoods] = useState([])
    const [finished, setFinished] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const pageCount = 20

    const [loading,setLoading] = useState(false)

    let loaded = false

    const listLoad = async () => {
        await getGoodsRequest()
    }

    const getGoodsRequest = async () => {
        if (!loaded) {
            setLoading(true)
            loaded = true
        }
        let response = await getGoods({page: page, count: pageCount})
        setLoading(false)
        let newList = response.data.data
        let total = response.data.total
        setTotal(total)
        if (newList.length > 0) {
            // 未加载完毕
            if (page > 1) {
                newList = goods.concat(...newList)
            }
            setGoods(newList)
            if (newList.length < pageCount) {
                // 已经加载完
                setFinished(true)
                return
            }
            setPage(page + 1)
            return
        }
        // 已经加载完毕
        setFinished(true)
    }

    let maxHeight = getScreenHeight() - 70

    let containerStyle = {
        width: "100%",
        height: `${maxHeight}px`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    }

    return (
        <div style={containerStyle}>
            <NavBar
                title="添加双开应用"
                safeAreaInsetTop
                placeholder
                leftArrow={false}
            />

            <div className={styles.listHeaderContainer}>
                <span className={styles.listHeaderText}>所有应用（{total}）</span>
            </div>

            <List finished={finished} onLoad={listLoad} loadingText={<></>} style={{flex: 1,overflowY:"scroll",paddingBottom: "40px"}} >
                {goods.map((item,index) => (
                    <Cell center
                          onClick={() => navigate(`/goods/${item.id}`)}
                          key={item.id}
                          title={item.title}
                          icon={<Image width={36} height={36} src={item.cover} radius={8} />}
                          titleClass={styles.goodsTitle}
                          extra={
                              <div className={styles.addButton}>
                                  <span className={styles.addButtonText}>添加</span>
                              </div>
                    }
                    />
                ))}
            </List>

            <LoadingViewGuide visible={loading} />
        </div>
    )
}
