import styles from "./Developer.module.css";
export default function Developer() {
    return (
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <p className={styles.headerTitle}>启用开发者模式</p>
            </div>

            <div className={styles.contentContainer}>
                <div className={styles.contentTitle}>步骤说明</div>

                <div className={styles.contentStepContainer}>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/developer/step1.png"
                        />
                        <div className={styles.contentStep1Desc}>1</div>
                    </div>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/developer/step2.png"
                        />
                        <div className={styles.contentStep1Desc}>2</div>
                    </div>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/developer/step3.png"
                        />
                        <div className={styles.contentStep1Desc}>3</div>
                    </div>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/developer/step4.png"
                        />
                        <div className={styles.contentStep1Desc}>4</div>
                    </div>
                    <div className={styles.contentStepItemContainer}>
                        <img
                            className={styles.contentStep1ItemImg}
                            alt=""
                            src="/images/developer/step5.png"
                        />
                        <div className={styles.contentStep1Desc}>5</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
