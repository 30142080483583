import styles from "./Me.module.css"
import {
    copyText,
    defaultAtRefer,
    getIsSafariStatus,
    getScreenHeight,
    jumpPage,
    productsVipObject,
    tutorialLink
} from "../../utils";
import {Image, Cell, Popup, Toast, NavBar} from "react-vant";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {getCustomerConfig, getUserInfo} from "../../api/at";

import LoadingView from "../../Components/LoadingView/LoadingView";

import Clipboard from "clipboard"
import moment from "moment";

export default function Me() {

    const navigate = useNavigate()

    const [customerVisible, setCustomerVisible] = useState(false)
    const [customerConfig, setCustomerConfig] = useState()
    const [loading, setLoading] = useState(false)

    const [userInfo, setUserInfo] = useState()

    const goWxPage = async (accountName, e) => {
        Clipboard.copy(accountName)
        Toast("复制成功~")
        jumpPage({url: "weixin://"}, e)
    }

    const copyUserId = () => {
        if (!userInfo) {
            Toast("复制失败")
            return
        }
        let user_id = userInfo.user_id
        Clipboard.copy(user_id.toString())
        Toast("复制成功~")
    }

    const getCustomerConfigRequest = async () => {
        try {
            let response = await getCustomerConfig()
            if (response.status !== 1) {
                return
            }
            let customerConfig = response.data
            if (!customerConfig.hasOwnProperty("type") ||
                !customerConfig.hasOwnProperty("wx_public_account") ||
                !customerConfig.hasOwnProperty("wx_account") ||
                !["wx_public_account", "wx_account"].includes(customerConfig.type)
            ) {
                return
            }
            setCustomerConfig(response.data)
        } catch (e) {
            console.log(e)
        }

    }

    const getUserInfoRequest = async () => {
        try {
            let response = await getUserInfo()
            if (response.status === 1) {
                setUserInfo(response.data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getUserInfoRequest()
        getCustomerConfigRequest()
    }, [])

    let maxHeight = getScreenHeight() - 70
    let containerStyle = {
        width: "100%",
        height: `${maxHeight}px`,
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        backgroundColor: '#ffffff',
        gap: 'var(--gap-base)'
    }

    return (
        <div style={containerStyle}>
            <NavBar
                title="设置"
                safeAreaInsetTop
                placeholder
                leftArrow={false}
            />

            {userInfo && (
                <div className={styles.userVipInfoWrapContainer}>
                    <div className={styles.userInfoContainer} onClick={copyUserId}>
                        <span className={styles.userInfoId}>用户ID：{userInfo.user_id}</span>
                        <span className={styles.userInfoIdPlaceholderText}>复制</span>
                    </div>

                    <div className={styles.userVipInfoContainer}>
                        {(userInfo.vip_type && userInfo.vip_expired_time) ? (
                            <>
                                {moment(userInfo.vip_expired_time).valueOf() > moment().valueOf() ? (
                                    <span className={styles.userVipInfoText}>已开通{productsVipObject[userInfo.vip_type]}有效期至{userInfo.vip_expired_time}</span>
                                ) : (
                                    <span className={styles.userVipInfoText}
                                          onClick={() => navigate("/upgrade")}>会员已过期,立即重新开通</span>
                                )}
                            </>
                        ) : (
                            <span className={styles.userVipInfoText}
                                  onClick={() => navigate("/upgrade")}>未开通会员,立即开通</span>
                        )}
                    </div>
                </div>
            )}


            <div className={styles.bannerContainer} onClick={jumpPage.bind(this, {url: tutorialLink})}>
                <Image width={screen.width - 30} height={((screen.width - 30) * 300 / 1122)} style={{
                    borderRadius: 9,
                    overflow: "hidden"
                }} src="/images/common/jiaocheng.png"/>
            </div>

            <div className={styles.cellContainer}>
                <Cell title='升级会员' border={false} titleClass={styles.cellTitle}
                      icon={<Image width={24} height={24} src="/images/me/vip.png"/>} isLink
                      onClick={() => navigate("/upgrade")}/>
                <Cell title='订单' border={false} titleClass={styles.cellTitle}
                      icon={<Image width={24} height={24} src="/images/me/order.png"/>} isLink
                      onClick={() => navigate("/order")}/>
                <Cell title='联系客服' border={false} titleClass={styles.cellTitle}
                      icon={<Image width={24} height={24} src="/images/me/customer.png"/>} isLink
                      onClick={() => setCustomerVisible(true)}/>
            </div>

            {customerConfig && (
                <Popup visible={customerVisible} onClose={() => setCustomerVisible(false)}
                       style={{borderRadius: "20px"}}>
                    <div className={styles.customerContainer}
                         onClick={goWxPage.bind(this, customerConfig[customerConfig.type])}>
                        <Image width={84} height={84}
                               src={customerConfig.type === 'wx_account' ? '/images/common/account_avatar.png' : '/images/common/public_account_avatar.png'}/>
                        <div className={styles.customerTitleContainer}>
                            <b>{customerConfig.type === 'wx_account' ? '微信客服' : '微信公众号'}：</b>
                            <b className={styles.customerTitle}>{customerConfig[customerConfig.type]}</b>
                        </div>
                        <div className={styles.customerTitlePlaceholderTextContainer}>
                            <span className={styles.customerTitlePlaceholderText}>（点击复制并跳转微信）</span>
                        </div>
                    </div>
                </Popup>
            )}

            <LoadingView visible={loading} text='充值中...'/>
        </div>
    )
}
