import styles from "./Detail.module.css";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {atBaseURL, getPageAppInfo, signQuery, signSubmit} from "../../api/at";
import {Dialog, Circle, Toast} from 'react-vant';

import moment from "moment";
import "moment/locale/zh-cn"

import translate from "../../config/lang";
import NativeShare from "nativeshare";
import ReactLoading from 'react-loading';
import {sleep} from "../../utils";

const Detail = () => {
    const navigate = useNavigate();
    const {app_id,active_code} = useParams()

    const [fivePictureStatus, setFivePicture] = useState(false)
    const [appInfo, setAppInfo] = useState([])
    const [fivePictureTypeCount, setFivePictureTypeCount] = useState(0)

    const [downloadIng,setDownloadIng] = useState(false)

    // 先创建一个实例
    const nativeShare = new NativeShare()

    const initFivePictureKey = [{
        key: 'pictures_iphone',
        name: "Iphone"
    }, {
        key: 'pictures_iphone_x',
        name: 'Iphone X'
    }, {
        key: 'pictures_ipad',
        name: 'Ipad'
    }]

    const [fivePictureKey, setFivePictureKey] = useState(initFivePictureKey)

    const resetFivePictureKey = () => {
        setFivePictureKey(initFivePictureKey)
    }

    useEffect(() => {
        if (isNaN(Number(app_id))) {
            navigate("/")
        }
        getPageAppInfo({app_id: Number(app_id)}).then(res => {
            if (res.status !== 1) {
                console.log(res)
                navigate("/")
                return
            }
            let appInfo = res.data
            let fivePictureTypeCount = 0
            let initFivePictureKey = []
            fivePictureKey.map(item => {
                if (appInfo.meta_info[item.key].length > 0) {
                    fivePictureTypeCount++
                    if (!fivePictureStatus && initFivePictureKey.length === 0) {
                        initFivePictureKey = [item]
                    }
                }
            })
            setFivePictureTypeCount(fivePictureTypeCount)
            setFivePictureKey(initFivePictureKey)
            setAppInfo(appInfo)
        }).catch(e => console.log(e))
    }, [])

    useEffect(() => {
        if (fivePictureStatus) {
            resetFivePictureKey()
        }
    }, [fivePictureStatus])

    const picturesText = () => {
        let texts = []

        if (appInfo.meta_info?.pictures_iphone?.length > 0) {
            texts.push('Iphone')
        }

        if (appInfo.meta_info?.pictures_iphone_x?.length > 0) {
            texts.push('IphoneX')
        }

        if (appInfo.meta_info?.pictures_ipad?.length > 0) {
            texts.push('Ipad')
        }
        if (texts.length < 1) {
            return ''
        }

        return texts.join('、')
    }

    const toFixed = (num, fractionDigits = 2) => {
        num = Number(num)
        if (isNaN(num)) {
            return 0
        }
        return num.toFixed(fractionDigits)
    }

    const scoreStars = (score) => {
        score = Number(score)
        if (isNaN(score)) {
            score = 0
        }
        score = Math.floor(score)
        let dom = []
        if (score > 0) {
            if (score > 5) {
                for (let i = 0; i <= 4; i++) {
                    dom.push(<img className={styles.pathIcon4} alt="" src="/images/detail/path.svg" key={`type-1-${i}`}/>)
                }
            } else {
                let hollow = 5 - score
                for (let i = 1; i <= score; i++) {
                    dom.push(<img className={styles.pathIcon4} alt="" src="/images/detail/path.svg" key={`type-1-${i}`}/>)
                }
                for (let i = 1; i <= hollow; i++) {
                    dom.push(<img className={styles.pathIcon4} alt="" src="/images/detail/path4.svg" key={`type-2${i}`}/>)
                }
            }
        } else {
            for (let i = 0; i <= 4; i++) {
                dom.push(<img className={styles.pathIcon4} alt="" src="/images/detail/path4.svg" key={`type-2${i}`}/>)
            }
        }
        return (
            <div className={styles.ratingStarsIcon}>
                {dom}
            </div>
        )
    }

    const getScoreCountText = (score) => {
        score = Number(score)
        if (isNaN(score)) {
            return 0
        }
        if (score < 10000) {
            return score
        }
        let lang = navigator.language
        if (lang === "zh" || lang === "zh-CN") {
            return (score / 10000).toFixed(2) + '万'
        }
        return (score / 1000).toFixed(2) + 'k'
    }

    const downloadHandle = async () => {
        setDownloadIng(true)
        try {
            let submitResponse = await signSubmit({active_code: active_code})
            if (submitResponse.code !== 200) {
                setDownloadIng(false)
                if (submitResponse.data === "params_error" || submitResponse.data === "submit_error") {
                    Toast("激活码错误")
                    return
                }
                Toast("安装失败，请稍后再试")
            }

            Dialog.alert({
                message: '签名过程需要2-3分钟,请耐心等待\n完成后，请点击打开->安装，然后回到桌面找到图表等待安装完成',
            })

            while (true) {
                let queryResponse = await signQuery({active_code: active_code})
                if (queryResponse.code !== 200) {
                    if (queryResponse.data === "params_error") {
                        setDownloadIng(false)
                        Toast("激活码错误")
                        break
                    }
                    setDownloadIng(false)
                    Toast("签名错误,请稍后再试")
                    break
                }

                if (queryResponse.data.url) {
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '要正常使用程序，需要开启【开发者模式】，是否前往开启教程页？',
                        onCancel: () => console.log('cancel'),
                        onConfirm: () => navigate("/developer"),
                    }).catch(e => console.log(e))
                    setDownloadIng(false)
                    let downloadUrl = "itms-services://?action=download-manifest&url="
                    let url = `${atBaseURL}/multi/sign/plist?url=${queryResponse.data.url}&bundle_id=${queryResponse.data.bundle_id}&app_name=${appInfo.meta_info.name}&logo_url=${appInfo.meta_info.logo}`
                    downloadUrl += encodeURIComponent(url)
                    location.href = downloadUrl
                    break
                }
                await sleep(2000)
            }


        }catch (e) {
            setDownloadIng(false)
            Toast("安装失败，请稍后再试")
            console.log(e)
        }

    }

    return (
        <div className={styles.detail}>
            {appInfo && (
                <>
                    <div className={styles.header}>
                    </div>

                    <div className={styles.detailHead}>
                        <img className={styles.imgIcon} alt="" src={appInfo.meta_info?.logo}/>
                        <div className={styles.headInner}>
                            <div className={styles.headTitle}>
                                <div className={styles.div1}>{appInfo.meta_info?.name}</div>
                                <div className={styles.div2}>{appInfo.meta_info?.introduction}</div>
                            </div>
                            <div className={styles.headBottomContainer}>
                                <div className={styles.headButton}>
                                    {downloadIng ? (
                                        <ReactLoading type='spinningBubbles' color='#3478f6' width={30} height='auto' />
                                    ) : (
                                        <div className={styles.button}
                                             onClick={downloadHandle.bind(this)}>
                                            <div
                                                className={styles.div1getButtonText}>{translate('common.getAppText')}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.detailDivider}>
                        <div className={styles.detailDividerChild}/>
                    </div>
                    <div className={styles.statistics}>
                        <div className={styles.scoreInner}>
                            <div
                                className={styles.div5}>{getScoreCountText(appInfo.score_count)} {translate('detail.scoreText')}</div>
                            <div className={styles.div6}>{toFixed(appInfo.score, 1)}</div>
                            {scoreStars(appInfo.score)}
                        </div>
                        <div className={styles.scoreInner}>
                            <div className={styles.div5}>{translate('detail.ageText')}</div>
                            <div className={styles.div6}>{appInfo.age}+</div>
                            <div className={styles.div9}>{translate('detail.ageTextTwo')}</div>
                        </div>
                        <div className={styles.scoreInner}>
                            <div className={styles.div5}>{translate('detail.chartText')}</div>
                            <div className={styles.div6}>#1</div>
                            <div className={styles.div12}>{appInfo.sub_category?.name}</div>
                        </div>
                        {appInfo.meta_info?.developer && (
                            <div className={styles.scoreInner}>
                                <div className={styles.div5}>{translate('detail.developerText')}</div>
                                <img
                                    className={styles.developerIcon}
                                    alt=""
                                    src="/images/detail/detail-developers-icon.svg"
                                />
                                <div className={styles.div12}>{appInfo.meta_info?.developer}</div>
                            </div>
                        )}
                        <div className={styles.scoreInner}>
                            <div className={styles.div5}>{translate('detail.languageText')}</div>
                            <div className={styles.div6}>{translate('detail.languageTextThree')}</div>
                            <div className={styles.div12}>{translate('detail.languageTextTwo')}</div>
                        </div>
                        <div className={styles.scoreInner}>
                            <div className={styles.div5}>{translate('detail.sizeText')}</div>
                            <div className={styles.div6}>{appInfo.size}</div>
                            <div className={styles.div12}>MB</div>
                        </div>
                    </div>
                    <div className={styles.detailDivider2}>
                        <div className={styles.detailDividerChild}/>
                    </div>
                    {fivePictureTypeCount > 0 && (
                        <>
                          <div className={styles.fivePictureDisplayContainer}>
                            {fivePictureKey.map((pictureField, pictureFieldKey) => {
                              let show = appInfo.meta_info[pictureField.key].length > 0
                              return show && (
                                  <div className={styles.fivePictureDisplayItemContainer} key={pictureFieldKey}>
                                    <div className={styles.fivePictureDisplay}>
                                      {
                                        appInfo.meta_info[pictureField.key].map((picture, pictureKey) => {
                                          return (
                                              <img className={styles.imgIcon1} alt="" src={picture}
                                                   key={pictureKey}/>
                                          )
                                        })
                                      }
                                    </div>
                                    {fivePictureStatus && (
                                        <div className={styles.fivePictureDisplayItemInfoContainer}>
                                          <img className={styles.fivePictureDisplayItemIcon} alt=""
                                               src={pictureField.key === "pictures_ipad" ? "/images/detail/ipad-icon.svg" : "/images/detail/models-icon.svg"}/>
                                          <span
                                              className={styles.div21}>{pictureField.name}</span>
                                        </div>
                                    )}
                                  </div>
                              )
                            })}
                          </div>
                          {!fivePictureStatus && (
                              <div className={styles.models} onClick={() => setFivePicture(true)}>
                                <div className={styles.frameParent}>
                                  <div className={styles.list}>
                                    {(appInfo.meta_info?.pictures_iphone?.length > 0 || appInfo.meta_info?.pictures_iphone_x?.length > 0) && (
                                        <img className={styles.modelsIcon} alt="" src="/images/detail/models-icon.svg"/>
                                    )}
                                    {appInfo.meta_info?.pictures_ipad?.length > 0 && (
                                        <img className={styles.modelsIcon} alt="" src="/images/detail/ipad-icon.svg"/>
                                    )}
                                  </div>
                                  <div className={styles.div21}>{picturesText()}</div>
                                </div>
                                <img className={styles.modelsChild} alt="" src="/images/detail/frame-133.svg"/>
                              </div>
                          )}
                        </>
                    )}
                  <div className={styles.detailDivider2}>
                        <div className={styles.detailDividerChild}/>
                    </div>

                    <div className={styles.text}>
                        <div className={`${styles.div22}`}
                             dangerouslySetInnerHTML={{__html: appInfo.meta_info?.desc}}/>
                    </div>
                    <div className={styles.detailDivider3}>
                        <div className={styles.detailDividerChild}/>
                    </div>


                    <div className={styles.rateReviewsTitle}>
                        <b className={styles.b}>{translate('detail.commentScoreText')}</b>
                    </div>
                    <div className={styles.scoreContent}>
                        <div className={styles.scoreContentInner}>
                            <div className={styles.score1}>
                                <div className={styles.scoreLeft}>
                                    <div className={styles.div27}>{toFixed(appInfo.score, 1)}</div>
                                    <div className={styles.ratingStars}>
                                        <img
                                            className={styles.ratingStarsIcon1}
                                            alt=""
                                            src="/images/detail/rating-stars-icon.svg"
                                        />
                                        <img
                                            className={styles.ratingStarsIcon2}
                                            alt=""
                                            src="/images/detail/rating-stars-icon1.svg"
                                        />
                                        <img
                                            className={styles.ratingStarsIcon3}
                                            alt=""
                                            src="/images/detail/rating-stars-icon2.svg"
                                        />
                                        <img
                                            className={styles.ratingStarsIcon4}
                                            alt=""
                                            src="/images/detail/rating-stars-icon3.svg"
                                        />
                                        <img
                                            className={styles.ratingStarsIcon5}
                                            alt=""
                                            src="/images/detail/rating-stars-icon4.svg"
                                        />
                                    </div>
                                </div>
                                <div className={styles.scoringBar}>
                                    <div className={styles.scoringBar1}>
                                        <div className={styles.scoringBar2}/>
                                    </div>
                                    <div className={styles.scoringBar1}>
                                        <div className={styles.scoringBar4}/>
                                    </div>
                                    <div className={styles.scoringBar1}>
                                        <div className={styles.scoringBar6}/>
                                    </div>
                                    <div className={styles.scoringBar1}>
                                        <div className={styles.scoringBar8}/>
                                    </div>
                                    <div className={styles.scoringBar1}>
                                        <div className={styles.scoringBar10}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.scoringLabels}>
                            <b className={styles.b1}>{translate('detail.outOfText')}</b>
                            <div
                                className={styles.div28}>{getScoreCountText(appInfo.score_count)} {translate('detail.ratingText')}</div>
                        </div>
                    </div>
                    <div className={styles.detailDivider3}>
                        <div className={styles.detailDividerChild}/>
                    </div>
                    <div className={styles.informationTitle}>
                        <b className={styles.b}>{translate('detail.informationTitle')}</b>
                    </div>

                    <div className={styles.informationItemContainer}>
                        <div className={styles.informationItemLabel}>{translate('detail.informationProvider')}</div>
                        <div className={styles.informationItemVal}>{appInfo.meta_info?.supplier}</div>
                    </div>
                    <div className={styles.informationItemContainer}>
                        <div className={styles.informationItemLabel}>{translate('detail.informationSize')}</div>
                        <div className={styles.informationItemVal}>{appInfo.size} MB</div>
                    </div>
                    <div className={styles.informationItemContainer}>
                        <div className={styles.informationItemLabel}>{translate('detail.informationCategory')}</div>
                        <div className={styles.informationItemVal}>{appInfo.sub_category?.name}</div>
                    </div>
                    <div className={styles.informationItemContainer}>
                        <div className={styles.informationItemLabel}>{translate('detail.informationLanguages')}</div>
                        <div className={styles.informationItemVal}>{translate('detail.informationLanguagesTwo')}</div>
                    </div>
                    <div className={styles.informationItemContainer}>
                        <div className={styles.informationItemLabel}>{translate('detail.informationAge')}</div>
                        <div className={styles.informationItemVal}>{appInfo.age}+</div>
                    </div>
                    <div className={styles.informationItemContainer}>
                        <div className={styles.informationItemLabel}>{translate('detail.informationPurchases')}</div>
                        <div className={styles.informationItemVal}>{translate('detail.informationPurchasesTwo')}</div>
                    </div>
                    <div className={styles.informationItemContainer}>
                        <div className={styles.informationItemLabel}>{translate('detail.informationCopyright')}</div>
                        <div className={styles.informationItemVal}>{appInfo.meta_info?.copyright}</div>
                    </div>
                    <div className={styles.informationItemContainer}>
                        <div
                            className={`${styles.informationItemLabel} ${styles.privacyPolicy}`}>{translate('detail.informationPrivacyPolicy')}</div>
                        <div className={styles.informationItemVal}>
                            <img
                                className={styles.returnIcon}
                                alt=""
                                src="/images/detail/detail-privacy-policy-icon.svg"
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Detail;
