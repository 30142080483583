import styles from "./OrderDetail.module.css"
import {useNavigate, useParams} from "react-router-dom";
import {Image, NavBar, Toast} from "react-vant";
import {copyText, getIsSafariStatus, getScreenHeight, jumpPage, tutorialLink, uuidPageUrl} from "../../utils";
import React, {useEffect, useState} from "react";
import {getOrderDetail} from "../../api/at";
export default function OrderDetail() {
    const navigate = useNavigate()
    const {orderNo} = useParams()

    const [order,setOrder] = useState()

    const bannerHeight = (screen.width - 30) * 300 / 1122

    const copyTextHandle = async text => {
        await copyText(text)
        Toast("复制成功")
    }

    const getOrderDetailRequest = async () => {
        try {
            let response = await getOrderDetail({order_no : orderNo})
            if (response.status !== 1) {
                navigate(-1)
                return
            }
            setOrder(response.data)
        }catch (e) {
            console.log(e)
            navigate(-1)
        }
    }

    const downloadPage = async (e) => {
        if (!order.redeem_code) {
            alert("安装失败,激活码不正确")
            return
        }
        navigate(`/udid/${order.redeem_code}`)
    }

    useEffect(() => {
        getOrderDetailRequest()
    },[])

    let maxHeight = getScreenHeight()

    let containerStyle = {
        width: "100%",
        height: `${maxHeight}px`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    }

    return (
        <div style={containerStyle}>

            <NavBar
                title="订单详情"
                safeAreaInsetTop
                placeholder
                onClickLeft={() => navigate(-1)}
            />

            {order && (
                <div className={styles.contentContainer}>
                    <div className={styles.orderItemContainer}>
                        <div className={styles.orderItemOutNoContainer} onClick={() => copyTextHandle(order.order_no)}>
                            <div className={styles.orderItemOUtNoLeftContainer}>
                                <span className={styles.orderItemOutNo}>订单编号：{order.order_no}</span>
                            </div>
                            <div>
                            <span className={styles.orderItemOutNo}>（点击复制）</span>
                            </div>
                        </div>
                        <div className={styles.orderItemInfoContainer}>
                            <div className={styles.orderItemInfoLogo}>
                                {order.goods ? (
                                    <Image width={64} height={64} src={order.goods.cover ? order.goods.cover : '/images/common/wechat.png'} style={{borderRadius: '11px',overflow:'hidden'}} />
                                ) : (
                                    <Image width={64} height={64} src="/images/common/wechat.png"/>
                                )}
                            </div>
                            <div className={styles.orderItemInfoRightContainer}>
                                <div className={styles.orderItemInfoRightTopContainer}>
                                    <div className={styles.orderItemInfoRightTopTitleContainer}>
                                        <span className={styles.orderItemInfoTitle}>{order.goods?.title}</span>
                                    </div>
                                    {/*<div className={styles.orderItemInfoCoinContainer}>*/}
                                    {/*    <Image width={18} height={18} src="/images/common/coin.png"/>*/}
                                    {/*    <span className={styles.orderItemInfoCoin}>{order.coin}</span>*/}
                                    {/*</div>*/}
                                </div>
                                {/*<div className={styles.orderItemInfoDescContainer}>*/}
                                {/*    <span className={styles.orderItemDescText}>{order.goods?.title}</span>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className={styles.codeContainer}>
                        <div className={styles.codeTitleContainer}>
                            <span className={styles.codeTitle}>激活码（点击可复制）</span>
                        </div>
                        <div className={styles.codeNumberContainer} onClick={() => copyTextHandle(order.redeem_code)}>
                            <span className={styles.codeNumber}>{order.redeem_code}</span>
                        </div>
                    </div>

                    <div className={styles.downloadButton} onClick={downloadPage.bind(this)}>
                        <span className={styles.downloadButtonText}>立即安装</span>
                    </div>

                    <div className={styles.bannerContainer}
                         onClick={jumpPage.bind(this, {url: tutorialLink})}>
                        <Image width={screen.width - 30}
                               height={bannerHeight}
                               style={{
                                   borderRadius: 9,
                                   overflow: "hidden"
                               }} src="/images/common/jiaocheng.png"/>
                    </div>

                </div>
            )}


        </div>
    )
}
