export var atApiHost = "https://at.supperapp666.pro/api/v1/"

export var defaultAtRefer = "3.2.1.37"
export var defaultSdkRefer = "2.4.0.37.0"

export var uuidPageUrl = "https://app.supperapp666.pro/udid/"

export var tutorialLink = "https://at.supperapp666.pro/images/multi/tutorial.mp4"
export var atReferCacheKey = "at_refer"
export var sdkReferCacheKey = "sdk_refer"
export var bottomHeightCacheKey = "_bottom_height"

export var isSafariCacheKey = "is_safari"

export var atTokenCacheKey = "_token"
export var sdkTokenCacheKey = "sdk_token"

export var products =  [
  {
    key: "wsc_tools_18",
    localizedPrice: "¥18.00",
    price: 18,
    send: 0,
    coin: 18
  },
  {
    key: "wsc_tools_45",
    localizedPrice: "¥45.00",
    price: 45,
    send: 0,
    coin: 45
  },
  {
    key: "wsc_tools_98",
    localizedPrice: "¥98.00",
    price: 98,
    send: 0,
    coin: 98
  },
  {
    key: "wsc_tools_198",
    localizedPrice: "¥198.00",
    price: 198,
    send: 20,
    coin: 198
  },
  {
    key: "wsc_tools_298",
    localizedPrice: "¥298.00",
    price: 298,
    send: 30,
    coin: 298
  },
  {
    key: "wsc_tools_648",
    localizedPrice: "¥648.00",
    price: 648,
    send: 130,
    coin: 648
  }
]

export var productsVipObject = {
  super_year: "年度会员",
  super_season: "季度会员",
  super_month: "月度会员",
}

export var productsVip = [
  {
    key: "super_year",
    introductoryPrice: "¥98.00",
    localizedPrice: "¥198.00",
    price: 198,
    title: "年度会员",
    userSelectRate: 90,
    recommendTxt: "两周年限时回馈"
  },
  {
    key: "super_season",
    introductoryPrice: "¥68.00",
    localizedPrice: "¥98.00",
    price: 98,
    title: "季度会员",
    userSelectRate: 5,
    recommendTxt: "限时6.9折"
  },
  {
    key: "super_month",
    introductoryPrice: "¥28.00",
    localizedPrice: "¥38.00",
    price: 38,
    title: "月度会员",
    userSelectRate: 5,
    recommendTxt: "推荐"
  },
]



export var jumpPage = ({url},e) => {
  e.stopPropagation();
  // if (app_id && !isNaN(Number(app_id))) {
  //   userAppEventReport({app_id: app_id,event: 'download'}).then(() => {})
  // }
  window.open(url,'_self')
}

export var sleep = time => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export var copyText = async text => {
  if (navigator.clipboard) {
    // clipboard api 复制
    await navigator.clipboard.writeText(text);
  } else {
    let textarea = document.createElement('textarea');
    document.body.appendChild(textarea);
    // 隐藏此输入框
    textarea.style.position = 'fixed';
    textarea.style.clip = 'rect(0 0 0 0)';
    textarea.style.top = '10px';
    // 赋值
    textarea.value = text;
    // 选中
    textarea.select();
    // 复制
    document.execCommand('copy', true);
    // 移除输入框
    document.body.removeChild(textarea);
  }
}

export var getIsSafariStatus  = () => {
  let safariStatus = localStorage.getItem(isSafariCacheKey)
  return !(safariStatus === null || safariStatus === "false");
}

export var generatePhoneNumber = () => {
  let prefix = [130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 145, 147, 150, 151, 152, 153, 155, 156, 157, 158, 159, 186, 187, 188];
  let prefixIndex = Math.floor(Math.random() * prefix.length);
  let phone = prefix[prefixIndex].toString();
  phone += "*".repeat(6)
  for (let i = 0; i < 4; i++) {
    phone += Math.floor(Math.random() * 10).toString();
  }
  return phone;
}

export var getScreenHeight = () => {
  let bottomHeight = localStorage.getItem(bottomHeightCacheKey)
  return innerHeight - Number(bottomHeight)
}
