import styles from "./LoadingView.module.css"
import {Overlay,Loading} from "react-vant";

export default function LoadingView(props) {

    const {visible,text} = props

    return (
        <Overlay visible={visible}>
            <div className={styles.overLayContainer}>
                <Loading/>
                <span className={styles.overLayText}>{text}</span>
            </div>
        </Overlay>
    )
}
