const locals = {
  zh: {
    tabBar: {
      home: "首页",
      me: "我的",
    },
    detail: {
      scoreText: '个评分',
      ageText: '年龄',
      ageTextTwo:'岁',
      chartText:'排行榜',
      developerText: '开发者',
      developerTextTow: '开发者',
      languageText: '语言',
      languageTextTwo: '中文',
      languageTextThree: 'ZH',
      sizeText: '大小',
      commentScoreText:'评分',
      seeAllCommentText:'查看全部',
      outOfText: '满分5分',
      ratingText: '个评分',
      versionTitle: '新功能',
      versionText: '版本',
      appPrivacyTitle:'App隐私',
      appPrivacyText: '\"已表明该App的隐私规范可能包括以下数据处理方式。更多相关信息，请参阅',
      appPrivacyTextTwo: '开发者隐私政策。',
      appPrivacyTextThree: '开发者\"',
      dataTitle:'用于追踪你的数据',
      dataText: '以下数据可能会用于在其他公司的 App 和网站中追踪你：',
      dataTwoText: '标识符',
      privacyText: '隐私规范可能因您使用的功能或您的年龄等因素而异。',
      privacyMoreText: '了解更多',
      informationTitle:'信息',
      informationProvider:'供应商',
      informationSize:'大小',
      informationCategory:'类别',
      informationLanguages:'语言',
      informationLanguagesTwo:'中文',
      informationAge:'年龄',
      informationPurchases:'App内购买',
      informationPurchasesTwo:'是',
      informationCopyright:'版权',
      informationPrivacyPolicy:'隐私政策',
    },
    common: {
      getAppText: '安装',
      appPurchases: 'App内购买',
      shareText: '分享',
      breakText: '返回',
      more:'更多'
    }
  },
  en: {
    tabBar: {
      home: "首页",
      me: "我的",
    },
    detail: {
      scoreText: '个评分',
      ageText: '年龄',
      ageTextTwo:'岁',
      chartText:'排行榜',
      developerText: '开发者',
      developerTextTow: '开发者',
      languageText: '语言',
      languageTextTwo: '中文',
      languageTextThree: 'ZH',
      sizeText: '大小',
      commentScoreText:'评分',
      seeAllCommentText:'查看全部',
      outOfText: '满分5分',
      ratingText: '个评分',
      versionTitle: '新功能',
      versionText: '版本',
      appPrivacyTitle:'App隐私',
      appPrivacyText: '\"已表明该App的隐私规范可能包括以下数据处理方式。更多相关信息，请参阅',
      appPrivacyTextTwo: '开发者隐私政策。',
      appPrivacyTextThree: '开发者\"',
      dataTitle:'用于追踪你的数据',
      dataText: '以下数据可能会用于在其他公司的 App 和网站中追踪你：',
      dataTwoText: '标识符',
      privacyText: '隐私规范可能因您使用的功能或您的年龄等因素而异。',
      privacyMoreText: '了解更多',
      informationTitle:'信息',
      informationProvider:'供应商',
      informationSize:'大小',
      informationCategory:'类别',
      informationLanguages:'语言',
      informationLanguagesTwo:'中文',
      informationAge:'年龄',
      informationPurchases:'App内购买',
      informationPurchasesTwo:'是',
      informationCopyright:'版权',
      informationPrivacyPolicy:'隐私政策',
    },
    common: {
      getAppText: '安装',
      appPurchases: 'App内购买',
      shareText: '分享',
      breakText: '返回',
      more:'更多'
    }
  }
}

export default locals
