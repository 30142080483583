import styles from "./LoadingViewGuide.module.css"
import {Overlay, Loading, Image} from "react-vant";
import React from "react";

export default function LoadingViewGuide(props) {

    const {visible, text} = props

    return (
        <Overlay visible={visible}>
            <div className={styles.overLayContainer}>
                <Loading/>
                <span className={styles.overLayText}>{text}</span>
            </div>

            <div className={styles.overLayImage}>
            <Image src="/images/common/guide1.png" />
            </div>
        </Overlay>
    )
}
