import axios from "axios";

import {atReferCacheKey, atTokenCacheKey, defaultAtRefer} from "../utils";

const host = process.env.NODE_ENV === 'production' ? 'https://app.supperapp666.pro' : ''
const token = localStorage.getItem(atTokenCacheKey)
let atRefer = localStorage.getItem(atReferCacheKey)
if (atRefer === null) atRefer = defaultAtRefer

export var atBaseURL = `${host}/api/v1`
const client = axios.create({
   baseURL: atBaseURL,
   headers: {
      'Authorization': `Bearer ${token}`,
      'language': navigator.language,
      'Content-Type': 'application/json',
      'Platform-Refer': atRefer
   }
})

export var autoRegister = async (data ,config) => {
   return new Promise((resolve, reject) => {
      return client.post('auth/register',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}

export var getUserInfo = async (data ,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/user/info',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var getGroups = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/group/list',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var getGoods = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/goods/list-new',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}

export var getGoodInfo = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/goods/show',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var getOrders = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/order/list',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}

export var getOrderDetail = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/order/show',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var buyOrder = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/goods/buy-new',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var getCustomerConfig = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/customer/config',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var alipayOrder = async (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/recharge-order/upgrade/alipay',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}

export var getPageAppInfo = (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/page/app/show',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var signQuery = (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/sign/query',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var signSubmit = (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/sign/submit',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var signQueryNew = (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/sign/query-new',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var signSubmitNew = (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/sign/submit-new',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}
export var activeCodeCheck = (data,config) => {
   return new Promise((resolve, reject) => {
      return client.post('multi/active-code/check',data,config).then(res => {
         resolve(res.data)
      }).catch(res => {
         reject(res)
      })
   })
}


