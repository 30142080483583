import styles from "./TabBar.module.css"
import {useNavigate} from "react-router-dom";
import translate from "../../config/lang";
import {Toast} from "react-vant";
import {useEffect, useState} from "react";
import {getIsSafariStatus, isSafariCacheKey} from "../../utils";

const TabBar = ({pathname}) => {
  const navigate = useNavigate();
  const tabs = [
    {
      name: translate('tabBar.home'),
      path: '/',
      icon: '/images/tabBar/home.png',
      selectIcon: '/images/tabBar/home-select.png'
    },
    {
      name: translate('tabBar.me'),
      path: '/me',
      icon: '/images/tabBar/me.png',
      selectIcon: '/images/tabBar/me-select.png'
    }
  ]

  return (
    <div className={styles.tabBarContainer}>
      {tabs.map((item, index) => {
        return (
          <div className={styles.tabBarItemContainer} key={index} onClick={() => {
            navigate(item.path)
          }}>
            <div className={styles.tabBarItemIconContainer}>
              <img
                className={styles.tabBarItemIcon}
                alt=""
                src={pathname === item.path ? item.selectIcon : item.icon}
              />
            </div>
            <b className={pathname === item.path ? styles.tabBarItemNameSelect : styles.tabBarItemName}>{item.name}</b>
          </div>
        )
      })}
    </div>
  );
}

export default TabBar
