import React, {useEffect, useState} from "react";
import styles from "./Upgrade.module.css"
import {NavBar, Image, NoticeBar, Swiper, Toast} from "react-vant";
import {generatePhoneNumber, getIsSafariStatus, getScreenHeight, productsVip} from "../../utils";
import {useNavigate} from "react-router-dom";
import {alipayOrder} from "../../api/at";
import ReactLoading from "react-loading";
import {order} from "../../api/sdk";
import LoadingView from "../../Components/LoadingView/LoadingView";

export default function Upgrade() {
    const navigate = useNavigate()
    const [currentProduct,setCurrentProduct] = useState(productsVip[0].key)

    const [loading,setLoading] = useState(false)

    const [noticePhones,setNoticePhones] = useState([])

    const buyHandle = async product => {
        setCurrentProduct(product)
        try {
            setLoading(true)
            let orderResponse = await order({
                "store_product_id": product,
                "cp_order_no": "multi"
            })
            if (orderResponse.status !== 1) {
                Toast("下单失败，请稍后再试")
                return
            }
            let response = await alipayOrder({goods_key: product,sdk_trade_no: orderResponse.data.order_no})
            setLoading(false)
            if (response.status !== 1) {
                Toast("下单失败，请稍后再试")
                return
            }
            location.href = response.data
        }catch (e) {
            setLoading(false)
            console.log(e)
            Toast("下单失败，请稍后再试")
        }
    }

    useEffect(() => {
        let phones = []
        let vipType = ["年度会员","季度会员","月度会员"]
        for (let i = 0; i < 3;i++) {
            let phone = generatePhoneNumber()
            let rIndex = parseInt((Math.random() * 2).toString())
            let text = vipType[rIndex]
            phones.push({phone,text})
        }
        setNoticePhones(phones)
    },[])


    let maxHeight = getScreenHeight()

    let containerStyle = {
        width: "100%",
        height: `${maxHeight}px`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column"
    }

    return (
        <div style={containerStyle}>
            <NavBar
                title="会员购买"
                safeAreaInsetTop
                placeholder
                onClickLeft={() => navigate(-1)}
            />

            <div className={styles.contentContainer}>
                <div className={styles.bannerContainer}>
                    <Image src="/images/upgrade/banner.png"/>
                </div>

                <div className={styles.vipContainer}>
                    {productsVip.map((item, index) => {
                        if (currentProduct === item.key) {
                            return (
                                <div className={styles.vipItemContainerSelect} key={item.key}
                                     onClick={() => buyHandle(item.key)}>
                                    <span className={styles.vipTitle}>{item.title}</span>
                                    <span className={styles.vipMoney}>{item.introductoryPrice}</span>
                                    <span className={styles.vipPlaceholder}>次年{item.localizedPrice}</span>

                                    <div className={styles.userSelectContainerSelect}>
                                        <span className={styles.userSelectText}>{item.userSelectRate}%用户选择</span>
                                    </div>
                                    <div className={styles.vipRecommandContainer}>
                                        <span className={styles.vipRecommandText}>{item.recommendTxt}</span>
                                    </div>
                                </div>
                            )
                        }

                        return (
                            <div className={styles.vipItemContainer} key={item.key} onClick={() => buyHandle(item.key)}>
                                <span className={styles.vipTitle}>{item.title}</span>
                                <span className={styles.vipMoney}>{item.introductoryPrice}</span>
                                <span className={styles.vipPlaceholder}>次年{item.localizedPrice}</span>

                                <div className={styles.userSelectContainer}>
                                    <span className={styles.userSelectText}>{item.userSelectRate}%用户选择</span>
                                </div>
                                <div className={styles.vipRecommandContainer}>
                                    <span className={styles.vipRecommandText}>{item.recommendTxt}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className={styles.vipBuyButton} onClick={() => buyHandle(currentProduct)}>
                    <span className={styles.vipBuyButtonText}>立即开通</span>
                </div>

                <div className={styles.noticeContainer}>
                    <NoticeBar background='#ffffff' color='#666'>
                        <Swiper
                            autoplay={3000}
                            indicator={false}
                            vertical
                            className='notice-swipe'
                        >
                            {noticePhones.map((item,index) => (
                                <Swiper.Item key={item.phone}>
                                <span className={styles.noticeText}>{item.phone} 在1分钟前购买了 <span
                                    className={styles.noticeVipText}>{item.text}</span></span>
                                </Swiper.Item>
                            ))}
                        </Swiper>
                    </NoticeBar>
                </div>

                <div className={styles.privilegeContainer}>
                    <div className={styles.privilegeTitleContainer}>
                        <span className={styles.privilegeTitle}>会员专属权益</span>
                    </div>
                    <div className={styles.privilegeContentContainer}>

                        <div className={styles.privilegeItemContainer}>
                            <Image src="/images/upgrade/double.png" width={42} height={42} fit='contain'/>
                            <div className={styles.privilegeTextContainer}>
                                <span className={styles.privilegeTextTitle}>双开畅玩</span>
                                <span className={styles.privilegeText}>不限制双开时长，畅玩</span>
                            </div>
                        </div>

                        <div className={styles.privilegeItemContainer}>
                            <Image src="/images/upgrade/func.png" width={42} height={42} fit='contain'/>
                            <div className={styles.privilegeTextContainer}>
                                <span className={styles.privilegeTextTitle}>百款功能</span>
                                <span className={styles.privilegeText}>超强防封，稳定如山</span>
                            </div>
                        </div>

                        <div className={styles.privilegeItemContainer}>
                            <Image src="/images/upgrade/func.png" width={42} height={42} fit='contain'/>
                            <div className={styles.privilegeTextContainer}>
                                <span className={styles.privilegeTextTitle}>图标隐藏</span>
                                <span className={styles.privilegeText}>隐藏应用图标和名称</span>
                            </div>
                        </div>

                        <div className={styles.privilegeItemContainer}>
                            <Image src="/images/upgrade/doppelganger.png" width={42} height={42} fit='contain'/>
                            <div className={styles.privilegeTextContainer}>
                                <span className={styles.privilegeTextTitle}>后台通知</span>
                                <span className={styles.privilegeText}>分身的消息通知功能</span>
                            </div>
                        </div>

                        <div className={styles.privilegeItemContainer}>
                            <Image src="/images/upgrade/ad.png" width={42} height={42} fit='contain'/>
                            <div className={styles.privilegeTextContainer}>
                                <span className={styles.privilegeTextTitle}>免广告</span>
                                <span className={styles.privilegeText}>无广告弹出，沉浸浏览</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <LoadingView visible={loading} />

        </div>
    )
}
