import React, {useEffect, useState} from "react";
import styles from "./Goods.module.css"
import {NavBar,Image,Button,Toast} from "react-vant";
import {useNavigate, useParams} from "react-router-dom";
import {buyOrder, getGoodInfo} from "../../api/at";
import LoadingView from "../../Components/LoadingView/LoadingView";
import {getIsSafariStatus, getScreenHeight} from "../../utils";

export default function Goods() {
    const navigate = useNavigate()

    const {id} = useParams()
    const [good,setGood] = useState(null)
    const [loading,setLoading] = useState(false)

    const getGoodInfoRequest = async () => {
        try {
            let response = await getGoodInfo({id: id})
            if (response.status !== 1) {
                navigate(-1)
                return
            }
            setGood(response.data)
        }catch (e) {
            navigate(-1)
            console.log(e)
        }
    }

    const buy = async () => {
        setLoading(true)
        if (good.stock < 1) {
            setLoading(false)
            Toast('库存不足')
            return;
        }
        try {
            let res = await buyOrder({id: good.id})
            setLoading(false)
            if (res.status !== 1) {
                if (res.data === "not_vip") {
                    Toast('请升级会员～')
                    navigate("/upgrade")
                    return
                }
                if (res.data === "not_permissions_buy") {
                    Toast("无法购买该商品，请升级对应会员等级")
                    navigate("/upgrade")
                    return
                }
                if (res.data === "not_goods") {
                    Toast("商品不存在或库存不足")
                    navigate(-1)
                    return
                }
                Toast('购买失败,请稍后再试')
                navigate(-1)
                return
            }
            let order = res.data
            navigate(`/order/${order.order_no}`)
            Toast('购买成功')
        }catch (e) {
            setLoading(false)
            Toast("购买失败,请稍后再试")
            return
        }
    }

    useEffect(() => {
        getGoodInfoRequest()
    },[])

    let maxHeight = getScreenHeight()

    let containerStyle = {
        width: "100%",
        height: `${maxHeight}px`,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
    }

    let imageStyle = {
        flex: 1,
        overflowY: "scroll",
        width: "100%"
    }



    return (
        <div style={containerStyle}>
            <NavBar
                    title="添加双开应用"
                    safeAreaInsetTop
                    placeholder
                    onClickLeft={() => navigate(-1)}
                />

            {good && (
                <div className={styles.contentContainer}>
                    <div style={imageStyle}>
                        <Image src={good.goods_details_img} fit='contain'/>
                    </div>
                     <div className={styles.submitButtonContainer}>
                         <div className={styles.submitButton} onClick={buy}>
                             <span className={styles.submitButtonText}>确定创建多开</span>
                         </div>
                     </div>
                </div>
            )}

            <LoadingView visible={loading} text='购买中...'/>
        </div>
    )
}
